
<script>

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
//import { authHeader } from '../../../helpers/fakebackend/auth-header';


/**
 * Advanced-form component
 */
export default {
  page: {
    title: "View Banner",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
   Multiselect,
    Layout,
    PageHeader,
    
  },
  data() {
    return {
      title: "View Banner",
      items: [
        {
          text: "Banner",
          href: "/",
        },
        {
          text: "View",
          active: true,
        },
      ],
      status :'',
      submitted: false,
      showDismissibleAlert :false,
      loading: false,
      bannerData:[],
      foodCourtBanner:[],
      foodCourtList:[],
      foodCourtID:"",
      cafeteriaID:"",
      cafeteriaBanner:[],






    };
  },


  methods:{


getBanners(){
        this.axios.post(this.$loggedRole+"/getAllBanners",{
          loginTypeID:this.$storageData.login_type,
          empTypeID:this.$storageData.profile.empTypeID,
          empID:this.$storageData.profile.pid,
        })
        .then((result)=>{
        this.bannerData = result.data.data;
       
            
    });
},

getFoodCourtBanners(){
 
    this.axios.post(this.$loggedRole+"/getFoodCourtBanners",{
        loginTypeID:this.$storageData.login_type,
        foodCourtID : this.foodCourtID.foodCourtID,
        empTypeID:this.$storageData.profile.empTypeID,
        empID:this.$storageData.profile.pid,
      })
        .then((result)=>{
        this.foodCourtBanner = result.data.foodCourtFinalArr;
            
    });
},

getCafeteriaBanners(){
    this.axios.post(this.$loggedRole+"/getCafeteriaBanners",{
      loginTypeID:this.$storageData.login_type,
      restaurantBranchID : this.cafeteriaID.restBranchID, // cafeteriaID = restBranchID
      empTypeID:this.$storageData.profile.empTypeID,
      empID:this.$storageData.profile.pid,
    })
        .then((result)=>{
        this.cafeteriaBanner = result.data.cafeteriaFinalArr;
            
    });
},

addNewBanner(enums,displayName){
         this.$router.push({name: 'bannerForm', params: { enum: enums,displayName:displayName,bannerID:0 }});
},

actionButton(flag,enums,displayName,bannerID){
    if(flag==1 || flag==2){
        this.$router.push({name: 'bannerForm', params: { enum: enums,displayName:displayName,bannerID:bannerID }});
    }else{
      if(confirm("Are you sure you want to delete this banner?")){
            this.axios.post(this.$loggedRole+"/deleteBanner",{bannerID:bannerID})
            .then(()=>{
                this.showDismissibleAlert = true;
                this.getBanners();
                   setTimeout(() => {
                      this.showDismissibleAlert = false;
                    },2000);
            });
      }
    }
},

    getFoodCourtList(){
         this.axios.post(this.$loggedRole+"/viewAllFoodCourt", {
            empTypeID:this.$storageData.profile.empTypeID,
            empID:this.$storageData.profile.pid,
            loginTypeID:this.$storageData.login_type
         })
          .then((result)=>{
            this.foodCourtList = result.data.data;   
            this.foodCourtID = (this.foodCourtList) ? this.foodCourtList[0] : ""; 
            this.getFoodCourtBanners();
        });
    },
    getCafeteriaList(){
         this.axios.get(this.$loggedRole+"/viewCafeRestaurant", {
            empTypeID:this.$storageData.profile.empTypeID,
            empID:this.$storageData.profile.pid,
            loginTypeID:this.$storageData.login_type
         })
          .then((result)=>{
            this.cafeteriaList = result.data.data;   
            this.cafeteriaID = (this.cafeteriaList) ? this.cafeteriaList[0] : ""; 
            this.getCafeteriaBanners();
        });
    },

  

  },
mounted(){
   this.getBanners();
   this.getFoodCourtList();
   this.getCafeteriaList();
   
  },

  middleware: "authentication",
};
</script>

<template>
<Layout>
    <PageHeader :title="title" :items="items" />
<div class="alertMsg">
     <b-alert v-model="showDismissibleAlert" variant="success" dismissible>
        Banner deleted Successfully!
    </b-alert>
  </div>


        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                
                     <div
                        v-for="(item) in this.bannerData"
                        :key="item.id"
                        class="mb-2"
                        
                        
                      > 
                      <div class="row">
                            <div class="col-md-10">
                                <h6>{{item.displayName}} </h6>
                            </div>
                            <div class="col-md-2 mb-2">
                                <button class="btn btn-themeYellow" @click="addNewBanner(item.enum,item.displayName);"> Add New </button>
                            </div>
                    </div>
                      <div class="row">
      <div
        class="col-xl-3 col-sm-6"
       v-for="(item2) in item.banners"
                                    :key="item2.id"
      >
        <div class="card text-center">
          <div class="card-body" style="padding:0px">
                  <div class="mb-4">
            
                <img :src="item2.bannerImage ? item2.bannerImage : $placeholder" class="profile-logo mb-2"  
                                        alt="thumb" style="width:100%; height:200px;float: left;margin-right: 10px;">     

         
            </div>
            <b-dropdown
              class="float-end"
              variant="white"
              menu-class="dropdown-menu-end"
              right
              toggle-class="font-size-16 text-body p-0"
            >
              <template v-slot:button-content style="    padding: 0px 9px;">
                <i class="uil uil-info-circle"></i>
              </template>
              <a class="dropdown-item" @click="actionButton(1,item.enum,item.displayName,item2.bannerID)" style="
    cursor: pointer">View</a>
              <a class="dropdown-item"  @click="actionButton(2,item.enum,item.displayName,item2.bannerID)" style="
    cursor: pointer;">Edit</a>
              <a class="dropdown-item" @click="actionButton(3,item.enum,item.displayName,item2.bannerID)" style="
    cursor: pointer;">Delete</a>
            </b-dropdown>
            <div class="clearfix"></div>
        
         
          </div>

        </div>
      </div>
    </div>

                    </div>
                </div>
               
            </div>
        </div>

        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                   <div class="mb-3 col-md-4">
                      <label>Select Cafeteria </label>
                      <multiselect v-model="cafeteriaID" :options="cafeteriaList" track-by="restBranchID" label="restaurantName" @input="getCafeteriaBanners();" ></multiselect>
                                         
                    </div>
                
                     <div
                        v-for="(item) in this.cafeteriaBanner"
                        :key="item.id"
                        class="mb-2"
                        
                        
                      > 
                      <div class="row">
                            <div class="col-md-10">
                                <h6>{{item.displayName}} </h6>
                            </div>
                            <div class="col-md-2 mb-2">
                                <button class="btn btn-themeYellow" @click="addNewBanner(item.enum,item.displayName);"> Add New </button>
                            </div>
                      </div>
                    <div class="row">
                      <div  
                        class="col-xl-3 col-sm-6"
                        v-for="(item2) in item.banners"
                        :key="item2.id"
                      >
                                <div class="card text-center">
                                  <div class="card-body" style="padding:0px">
                                          <div class="mb-4">
                                    
                                        <img :src="item2.bannerImage ? item2.bannerImage : $placeholder" class="profile-logo mb-2"  
                                                                alt="thumb" style="width:100%; height:200px;float: left;margin-right: 10px;">     

                                
                                    </div>
                                    <b-dropdown
                                      class="float-end"
                                      variant="white"
                                      menu-class="dropdown-menu-end"
                                      right
                                      toggle-class="font-size-16 text-body p-0"
                                    >
                                      <template v-slot:button-content style="    padding: 0px 9px;">
                                        <i class="uil uil-info-circle"></i>
                                      </template>
                                      <a class="dropdown-item" @click="actionButton(1,item.enum,item.displayName,item2.bannerID)" style="
                            cursor: pointer">View</a>
                                      <a class="dropdown-item"  @click="actionButton(2,item.enum,item.displayName,item2.bannerID)" style="
                            cursor: pointer;">Edit</a>
                                      <a class="dropdown-item" @click="actionButton(3,item.enum,item.displayName,item2.bannerID)" style="
                            cursor: pointer;">Delete</a>
                                    </b-dropdown>
                                    <div class="clearfix"></div>
                                
                                
                                  </div>

                                </div>
                              </div>
                            </div>

                    
                    </div>
                </div>
               
            </div>
        </div>


       <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                   <div class="mb-3 col-md-4">
                      <label>Select Food Court </label>
                      <multiselect v-model="foodCourtID" :options="foodCourtList" track-by="foodCourtID" label="title" @input="getFoodCourtBanners();" ></multiselect>
                                         
                    </div>
                
                     <div
                        v-for="(item) in this.foodCourtBanner"
                        :key="item.id"
                        class="mb-2"
                        
                        
                      > 
                      <div class="row">
                            <div class="col-md-10">
                                <h6>{{item.displayName}} </h6>
                            </div>
                            <div class="col-md-2 mb-2">
                                <button class="btn btn-themeYellow" @click="addNewBanner(item.enum,item.displayName);"> Add New </button>
                            </div>
                    </div>
      <div class="row">
        
      <div  
        class="col-xl-3 col-sm-6"
       v-for="(item2) in item.banners"
                                    :key="item2.id"
      >
        <div class="card text-center">
          <div class="card-body" style="padding:0px">
                  <div class="mb-4">
            
                <img :src="item2.bannerImage ? item2.bannerImage : $placeholder" class="profile-logo mb-2"  
                                        alt="thumb" style="width:100%; height:200px;float: left;margin-right: 10px;">     

         
            </div>
            <b-dropdown
              class="float-end"
              variant="white"
              menu-class="dropdown-menu-end"
              right
              toggle-class="font-size-16 text-body p-0"
            >
              <template v-slot:button-content style="    padding: 0px 9px;">
                <i class="uil uil-info-circle"></i>
              </template>
              <a class="dropdown-item" @click="actionButton(1,item.enum,item.displayName,item2.bannerID)" style="
    cursor: pointer">View</a>
              <a class="dropdown-item"  @click="actionButton(2,item.enum,item.displayName,item2.bannerID)" style="
    cursor: pointer;">Edit</a>
              <a class="dropdown-item" @click="actionButton(3,item.enum,item.displayName,item2.bannerID)" style="
    cursor: pointer;">Delete</a>
            </b-dropdown>
            <div class="clearfix"></div>
        
         
          </div>

        </div>
      </div>
    </div>

                    
                    </div>
                </div>
               
            </div>
        </div>
</Layout>
</template>
<style>
.alertMsg{
  position: fixed;
    right: 0px;
    z-index: 500;
    top: 79px;
    width: 22%;
}
    .preBuyImageTempalet1
{
      height: 200px;
    width: 200px;
    -o-object-fit: contain;
    object-fit: contain;
}

.box {
            position: relative;
            width: 100%;
            max-width: 250px;
         }
         .box img {
            width: 100%;
            height: auto;
         }
         .box .btn {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
         }
</style>


